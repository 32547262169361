@import '../../assets/styles/mixins'

.trustedCard
  padding: 44px
  user-select: none
  background: var(--Button-fill-primary)
  border-radius: 32px
  max-width: 405px
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  cursor: pointer
  transition: all 0.3s ease 0s
  position: relative
  z-index: 5
  @media (max-width: 769px)
    max-width: 100%
  @media (max-width: 576px)
    padding: 32px
  &Logo
    width: 100%
    height: 100%
    object-fit: cover
    display: flex
    align-items: center
    justify-content: center
    @media (max-width: 993px)
      min-width: 100%
    @media (max-width: 576px)
      width: 72px
      height: 72px
  &:hover
    opacity: 0.7
  &Title
    +montserrat(32px, 600, #fff, 100%)
    margin-top: 24px
    @media (max-width: 576px)
      +montserrat(24px, 600, #fff, 100%)
