.scrollableContainer
  max-width: 100%
  width: 100%
  overflow-x: auto
  overflow-y: hidden
  &::-webkit-scrollbar 
    display: none
  &Wrapper
    position: relative
    user-select: none
  &Btn, &BtnLeft
    position: absolute
    top: 50%
    right: 3vw
    transform: translateY(-50%)
    cursor: pointer
    border-radius: 20px
    background: #2E3238
    height: 54px
    width: 54px
    padding: 10px
    display: flex
    align-items: center
    justify-content: center
    transition: all ease 200ms
    &:hover
      scale: (1.05)
  &BtnLeft
    left: 10% !important