@import '../../assets/styles/mixins'

.expandableCardSlider
  display: flex
  gap: 24px
  user-select: none
  padding: 0 15px
  &Btn
    position: absolute
    top: 50%
    right: 3vw
    transform: translateY(-50%)
    cursor: pointer
    transition: all ease 200ms
    &:hover
      scale: (1.05)
  &Wrapper
    position: relative
.expandableCard
  height: 555px
  border-radius: 24px
  background: var(--Background-tertiary)
  // transition: all ease 500ms
  min-width: 148px
  @media (max-width: 576px)
    height: 590px
  .index
    +montserrat(32px, 600, #fff, 150%)
    @media (max-width: 576px)
      +montserrat(24px, 600, #fff, 150%)
.expanded
  min-width: 994px
  @media ( max-width: 1200px )
    min-width: 446px
  @media (max-width: 576px)
    min-width: auto
.collapsedCard
  padding: 24px 24px
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  cursor: pointer
  @media (max-width: 576px)
    padding: 19px 10px
  &Stroke
    flex-grow: 1
    border-left: 2px dashed #FFFFFF33
    width: 2px
  &Logo
    width: 102px
    height: 102px
    border-radius: 50%
    img
      border-radius: 50% !important
.expandedCard
  display: flex
  gap: 32px
  padding: 24px 32px
  height: 100%
  max-height: 100%
  user-select: none
  width: 100%
  @media (max-width: 1000px)
    max-width: max-content
  @media (max-width: 576px)
    padding: 24px 19px
    max-width: 350px
  &Content
    display: flex
    flex-direction: column
    flex: 1 0 0
  &Img
    width: 445px
    height: 100%
    border-radius: 20px
    background-repeat: no-repeat
    background-size: cover
    flex: 1 0 0
    @media ( max-width: 1200px )
      display: none
  &Header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 24px
  &Title
    +montserrat(32px, 700, #fff, 150%)
    margin-bottom: 0.5em
  &Desc
    +montserrat(20px, 500, #fff, 150%)
    margin-bottom: 10px
  &Main
    flex: 1 0 0
  &Footer
    display: flex
    align-items: flex-end
    justify-content: space-between
    gap: 24px
    @media (max-width: 1000px)
      align-items: center
      button
        max-width: 335px
    img
      border-radius: 50%
    @media (max-width: 370px)
      img
        max-width: 70px
        max-height: 70px
  &Btn
    flex-grow: 1
    > *
      width: 100%
      > *
        width: 100%
    &:target
      pointer-events: none
