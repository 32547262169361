.trustedPartners
  margin-top: 1070px
  margin-bottom: 320px
  position: relative
  @media (max-width: 1715px)
    margin-top: 1300px
    margin-bottom: 280px
  @media (max-width: 1400px)
    margin-top: 300px
    margin-bottom: 280px
  @media (max-width: 1201px)
    margin-bottom: 250px
  @media (max-width: 1160px)
    margin-top: 70px
  @media (max-width: 993px)
    margin-bottom: 170px
  @media (max-width: 769px)
    margin-bottom: 110px
  @media (max-width: 576px)
    margin-bottom: 90px


.decorI
  position: absolute
  opacity: 0.2
  height: 700px
  width: 700px
  border-radius: 50%
  top: 0
  right: 0
  background: #830BE1
  filter: blur(150px)
  -webkit-filter: blur(150px)
  animation: dissipate 4s infinite alternate
  @keyframes dissipate
    0%
      opacity: 0.2
    50%
      opacity: 0.1
    70%
      opacity: 0.15
    100%
      opacity: 0.2
      @media (max-width: 769px)
        height: 500px
        width: 500px
      @media (max-width: 420px)
        height: 400px
        width: 400px

.trustedDecor
  position: absolute
  left: 0
  top: -130%
  z-index: -1
  img
    max-width: 100%
    max-height: 100%
  @media (max-width: 1280px)
    top: -90%
  @media (max-width: 1160px)
    top: -70%
  @media (max-width: 1000px)
    top: -50%
  @media (max-width: 800px)
    top: -45%
  @media (max-width: 730px)
    top: -35%
  @media (max-width: 600px)
    top: -30%
  @media (max-width: 370px)
    top: -25%
.title
  margin-bottom: 80px
  @media (max-width: 769px)
    margin-bottom: 50px
    br
      display: none
  @media (max-width: 576px)
    margin-bottom: 32px

.contentWrapper
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  gap: 20px
  @media (max-width: 993px)
    display: grid
    grid-template-columns: repeat(2, 1fr)
    justify-items: center
  @media (max-width: 769px)
    grid-template-columns: repeat(1, 1fr)
