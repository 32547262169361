@import '../../../../assets/styles/mixins'

.roadmap
  position: relative
  margin-bottom: 320px
  @media (max-width: 1401px)
    margin-bottom: 280px
  @media (max-width: 1201px)
    margin-bottom: 250px
  @media (max-width: 993px)
    margin-bottom: 170px
  @media (max-width: 769px)
    margin-bottom: 110px
  @media (max-width: 576px)
    margin-bottom: 90px
.contentWrapper
  display: flex
  align-items: flex-start
  width: 100%
  overflow: auto
  cursor: grab
  user-select: none
  &::-webkit-scrollbar
    width: 0

  &::-webkit-scrollbar-track
    background-color: transparent

  &::-webkit-scrollbar-thumb
    background-color: transparent
    border-radius: 4px

  &::-webkit-scrollbar-thumb:hover
    background-color: transparent

.title
  margin-bottom: 80px
  @media (max-width: 769px)
    margin-bottom: 50px
  @media (max-width: 576px)
    margin-bottom: 32px

.item
  width: 100%
  user-select: none
  &Roadmap
    display: flex
    align-items: center
    &Dot
      width: 40px
      height: 40px
      border-radius: 50%
      background: var(--Background-quaternary)
      position: relative
      &::before
        content: ''
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        background: #fff
        width: 15px
        height: 15px
        border-radius: 50%
    &Road
      position: relative
      width: 100%
      &::after
        content: ''
        position: absolute
        width: 100%
        height: 1px
        border-bottom: 2px dashed var(--Background-quaternary)
      &Done
        &::after
          border-bottom: 2px dashed var(--Accent-success) !important
  &Body
    margin-top: 40px
    width: 500px
    padding-right: 64px
    @media (max-width: 495px)
      padding-right: 150px
    @media (max-width: 420px)
      padding-right: 180px
    @media (max-width: 390px)
      padding-right: 200px
    @media (max-width: 360px)
      padding-right: 220px
    @media (max-width: 340px)
      padding-right: 240px
  &Stage
    width: max-content
    border-radius: 50px
    background: var(--Background-quaternary)
    padding: 12px 16px
    +montserrat(18px, 600, var(--Placeholder-primary), 130%)
    margin-bottom: 24px
    @media (max-width: 576px)
      max-width: 100%
      +montserrat(16px, 600, var(--Placeholder-primary), 130%)
    &Active
      background: var(--Accent-success)
  &Title
    +montserrat(32px, 600, var(--Placeholder-primary), 120%)
    margin-bottom: 24px
    @media (max-width: 576px)
    +montserrat(24px, 600, var(--Placeholder-primary), 120%)
  &Description
    +montserrat(20px, 500, #CFD3D7, 160%)
    @media (max-width: 576px)
      +montserrat(16px, 600, var(--Placeholder-primary), 120%)

.decorI
  position: absolute
  opacity: 0.2
  height: 700px
  width: 700px
  border-radius: 50%
  top: 70%
  right: 0
  background: #14F1B7
  filter: blur(170px)
  -webkit-filter: blur(170px)
  animation: dissipate 4s infinite alternate
  @keyframes dissipate
    0%
      opacity: 0.2
    50%
      opacity: 0.1
    70%
      opacity: 0.15
    100%
      opacity: 0.2
      @media (max-width: 769px)
        height: 500px
        width: 500px
      @media (max-width: 420px)
        height: 400px
        width: 400px
