@import '../../assets/styles/mixins'

.navigation
  display: flex
  align-items: center
  gap: 6px
  .item
    cursor: pointer
    position: relative
    &Wrapper
      display: flex
      align-items: center
      gap: 8px
    &Link
      text-transform: uppercase
      padding: 6px 12px
      transition: all ease 200ms
      +montserrat(20px, 600, rgba(255,255,255,0.4))
      user-select: none
      &:hover
        color: rgba(255,255,255,1)
      // &Active
      //   color: rgba(255,255,255,1)
    &DD
      position: absolute
      left: -10px
      background: var(--Background-tertiary)
      padding: 8px 0
      border-radius: 16px
      width: max-content
      &Child
        padding: 6px 10px
.navigationMobile
  position: fixed
  top: 0
  left: 0
  right: 0
  width: 100vw
  overflow: auto
  background: #212125
  padding: 0px 12px
  padding-bottom: 32px
  max-height: 100vh
  transition: all ease 200ms
  &Header
    display: flex
    align-items: center
    width: 100%
    justify-content: space-between
    padding: 30px 12px
  &List
    display: flex
    flex-direction: column
    gap: 12px
    max-height: calc(100vh - 100px - 32px)
    overflow-y: auto
  &Btn
    width: 100%
  &Btns
    display: flex
    flex-direction: column
    gap: 12px
    margin-top: 24px
  .itemMobile
    padding: 6px 12px
  .itemDDMobile
    position: relative
    border-left: 3px solid var(--Border-Secondary)
    border-radius: 0px
    background: transparent
    margin-left: 20px
    margin-top: 12px
    display: flex
    flex-direction: column
    gap: 12px

.logo
  position: relative
  cursor: pointer