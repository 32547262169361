@import '../../assets/styles/mixins'

.list
  padding: 20px 40px
  border-radius: 48px
  background: rgba(33, 35, 37, 0.60)
  @media (max-width: 576px)
    padding: 8px 24px

.card
  padding: 30px 0
  @media (max-width: 576px)
    padding: 16px 0
  &:not(:last-child)
    border-bottom: 1px solid var(--Border-primary, #2E353D)
  &Top
    display: flex
    align-items: center
    justify-content: space-between
    gap: 5px
    &Active
      margin-bottom: 24px
      @media (max-width: 576px)
        margin-bottom: 16px
    &Title
      +montserrat(24px, 600, #fff, 130%)
      cursor: pointer
      transition: all 0.3s ease 0s
      @media (max-width: 576px)
        +montserrat(18px, 600, #fff, 130%)
      &:hover
        opacity: 0.8
  &Description
      +montserrat(20px, 500, #CFD3D7, 160%)
      max-width: 945px
      margin-top: 24px
      @media (max-width: 576px)
        +montserrat(16px, 500, #fff, 160%)
  &Actions
    padding: 12px !important
    border-radius: 16px
    transition: all 0.3s ease 0s
    &:hover
      opacity: 0.8
    &Active
      transform: rotate(180deg)
