@import '../../../../assets/styles/mixins'

.productsBlock
  .title
    margin-bottom: 1em
    @media ( max-width: 1400px )
      padding: 0 55px
    //
    @media (max-width: 768px)
      margin-bottom: 50px
      padding: 0
    @media (max-width: 576px)
      margin-bottom: 32px