@import '../../assets/styles/mixins'

.card
  padding: 8px
  border-radius: 32px

  background: var(--Background-tertiary)
  height: 650px
  overflow: hidden
  display: flex
  flex-direction: column
  @media (max-width: 1260px)
    height: 700px
  @media (max-width: 762px)
    height: 750px
  @media (max-width: 572px)
    height: 700px
  @media (max-width: 374px)
    height: 770px
  &Img
    margin-bottom: 24px
    img
      border-radius: 24px
      width: 100%
      max-height: 295px
      min-height: 295px
      object-fit: cover
  &Body
    padding: 0 24px
    flex: 1
    margin-bottom: 32px
    @media (max-width: 1550px)
      margin-bottom: 15px
    @media (max-width: 1260px)
      margin-bottom: 10px
    @media (max-width: 769px)
      padding: 0 20px
    @media (max-width: 577px)
      padding: 0 10px
      margin-bottom: 0
  &Title
    +montserrat(32px, 600, #fff, 100%)
    margin-bottom: 20px
    @media (max-width: 576px)
      +montserrat(27px, 600, #fff, 100%)
      margin-bottom: 12px
  &Divider
    width: 100%
    height: 1px
    background: var(--Border-primary)
    margin-bottom: 20px
    @media (max-width: 576px)
      margin-bottom: 12px
  &Description
    height: 100%
    overflow: hidden
    +montserrat(18px, 500, rgba(255, 255, 255, 0.8), 150%)
    @media (max-width: 576px)
      margin-bottom: 16px
  &Button
    margin-top: auto
    width: 100%
    +montserrat(20px, 600, rgba(255, 255, 255, 0.8), 150%)
    gap: 24px
    transition: all 0.3s ease 0s
    &:hover
      opacity: 0.7
