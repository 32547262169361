@import '../../assets/styles/mixins'

.navbar
  width: 100%
  padding: 10px 0
  position: fixed
  top: -1px
  z-index: 25
  .wrapper
    display: flex
    align-items: center
    gap: 2.8%
    @media ( max-width: 1400px )
      gap: 24px
  .navigationWrapper
    flex: 1 0 0
    @media ( max-width: 1400px )
      order: 3
      flex-grow: 0
    @media ( max-width: 768px )
      flex-grow: 1
      display: flex
      justify-content: flex-end
  .buttonsWrapper
    display: flex
    align-items: center
    gap: 16px
    @media ( max-width: 1400px )
      order: 2
      flex-grow: 1
      justify-content: flex-end
    @media ( max-width: 768px )
      display: none
      
.logo
  cursor: pointer

.activeHeader 
  background: #11111277
  backdrop-filter: blur(10px)
  -webkit-backdrop-filter: blur(10px)