@import '../../../../assets/styles/mixins'

.tonPotential
  margin-top: 50px
  padding-top: 350px
  padding-bottom: 70px
  overflow: hidden
  position: relative
  @media (max-width: 769px)
    margin-top: 0
  @media (max-width: 576px)
    padding-top: 280px
  .title
    margin-bottom: 80px
    @media (max-width: 769px)
      margin-bottom: 50px
    @media (max-width: 576px)
      margin-bottom: 32px
  .contentWrapper
    position: relative
    padding-left: 53px
    display: flex
    justify-content: space-between
    // @media ( max-width: 1400px )
    //   display: block
    @media (max-width: 769px)
      padding-left: 35px
    @media (max-width: 576px)
      padding-left: 30px
    @media (max-width: 1490px)
      flex-direction: column
    .dot
      position: absolute
      top: 6px
      left: 1px
      width: 24px
      height: 24px
      background: #FFFFFF33
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      > div
        width: 10px
        height: 10px
        border-radius: 50%
        background-color: #fff
    &::before
      content: ""
      position: absolute
      width: 100vw
      height: 100%
      border-radius: 0px 0px 0px 33px
      border-bottom: 2px dashed #FFFFFF33
      border-left: 2px dashed #FFFFFF33
      left: 12px
      bottom: -24px
  .text
    display: block
    position: relative
    +mediumTextFont
    text-align: justify
    @media (max-width: 768px)
      +montserrat(16px, 500, rgba(255, 255, 255, 0.80), 150%)
    &Floated
      max-width: 288px
      @media (max-width: 1490px)
        max-width: 100%

  .leftSide
    flex-basis: 48%
  .rightSide
    flex-basis: 48%

// .btn
//   position: relative
//   z-index: 3

.diamondBig,
.coin,
.diamondSmall
  position: absolute

.diamondBig
  max-width: 550px
  max-height: 500px
  right: 10px
  top: 90px
  animation: levitate-big 3s ease-in-out infinite
  @media (max-width: 1590px)
    max-width: 500px
    top: 130px
    right: -15px
  @media (max-width: 1490px)
    top: -80px
  @media (max-width: 1280px)
    max-width: 400px
    max-height: 400px
    top: -200px
  // @media (max-width: 1080px)
  //   top: 250px
  //   max-width: 303px
  //   max-height: 261px
  @media (max-width: 880px)
    max-width: 230px
    max-height: 240px
    top: -80px
  @media (max-width: 630px)
    max-width: 190px
    max-height: 200px
    top: -60px
  @media (max-width: 576px)
    max-width: 170px
    max-height: 180px
    top: -80px
    right: 40px
  @media (max-width: 400px)
    right: 0
  @media (max-width: 360px)
    max-width: 140px
    top: -60px
    right: -25px

.coin
  max-width: 276px
  max-height: 336px
  top: -140px
  right: 280px
  animation: levitate-coin 3s ease-in-out infinite
  @media (max-width: 1580px)
    right: 190px
  @media (max-width: 1490px)
    top: -300px
    right: 200px
  @media (max-width: 1280px)
    max-width: 200px
    max-height: 200px
    top: -330px
    right: 200px
  // @media (max-width: 1080px)
  //   max-width: 128px
  //   max-height: 151px
  //   top: 130px
  //   right: 150px
  @media (max-width: 880px)
    max-width: 130px
    max-height: 130px
    top: -170px
    right: 90px
  @media (max-width: 630px)
    max-width: 115px
    max-height: 120px
    top: -150px
  @media (max-width: 576px)
    max-width: 100px
    max-height: 110px
    top: -160px
    right: 125px
  @media (max-width: 400px)
    right: 95px
  @media (max-width: 360px)
    top: -140px
    right: 30px
.diamondSmall
  max-width: 269px
  max-height: 214px
  top: 50px
  right: 530px
  animation: levitate-small 3s ease-in-out infinite
  @media (max-width: 1590px)
    right: 450px
  @media (max-width: 1490px)
    top: -80px
  @media (max-width: 1280px)
    max-width: 200px
    max-height: 200px
    top: -200px
    right: 350px
  // @media (max-width: 1080px)
  //   max-width: 120px
  //   max-height: 96px
  //   right: 280px
  //   top: 210px
  @media (max-width: 880px)
    max-width: 150px
    max-height: 150px
    top: -130px
    right: 210px
  @media (max-width: 630px)
    max-width: 100px
    max-height: 90px
    right: 180px
    top: -80px
  @media (max-width: 576px)
    max-width: 80px
    max-height: 70px
    right: 190px
    top: -70px
  @media (max-width: 400px)
    right: 170px
  @media (max-width: 360px)
    top: -70px
    right: 110px

@keyframes levitate-big
  0%
    transform: translateY(0)
  50%
    transform: translateY(-5px)
  100%
    transform: translateY(0)

@keyframes levitate-coin
  0%
    transform: translateY(0)
  50%
    transform: translateY(-6px)
  100%
    transform: translateY(0)

@keyframes levitate-small
  0%
    transform: translateY(0)
  50%
    transform: translateY(-13px)
  100%
    transform: translateY(0)

.decorI
  position: absolute
  opacity: 0.3
  height: 700px
  width: 700px
  border-radius: 50%
  top: -300px
  right: 0
  background: #3ab8ea
  filter: blur(150px)
  -webkit-filter: blur(150px)
  animation: dissipate 4s infinite alternate
  @media (max-width: 1490px)
    top: -900px
    opacity: 0.6
  @media (max-width: 1080px)
    opacity: 0.8
    top: -1000px
  @keyframes dissipate
    0%
      opacity: 0.2
    50%
      opacity: 0.1
    70%
      opacity: 0.15
    100%
      opacity: 0.2
      @media (max-width: 769px)
        height: 500px
        width: 500px
      @media (max-width: 420px)
        height: 400px
        width: 400px

.rightSideMobile
  @media (max-width: 769px)
    margin-top: 40px
  

.decor
  position: relative
  top: 10px
  width: 100%
  height: 40px
  background-color: #000
  filter: blur(30px)
  -webkit-filter: blur(30px)

.btn
  margin-top: 30px
  position: relative
  z-index: 3
  padding: 16px 32px
  &Active
    svg
      transform: rotate(180deg)
  @media (min-width: 769)
    display: none
