@import '../../../../assets/styles/mixins'

.headBlock
  height: 100vh
  width: 100vw
  max-width: 100vw
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative
  .title
    +montserrat(184px, 800, #FFFFFF, 121%)
    @media ( max-width: 1200px )
      font-size: 112px
      margin-left: 9vw
    @media ( max-width: 768px )
      +montserrat(68px, 800, #FFFFFF, 121%)
      margin-left: 0
    @media (max-width: 490px)
      +montserrat(48px, 800, #FFFFFF, 121%)
  .capital
    max-width: 100%
    width: 100%
    position: relative
    > svg
      max-width: 100%
      width: 100%
    &::after
      content: ""
      position: absolute
      width: 747px
      height: 747px
      opacity: 0.5
      background-color: #830BE1
      border-radius: 50%
      filter: blur(200px)
      left: 8%
      top: 0
  .heading
    flex: 1 0 0
    display: flex
    flex-direction: column
    background-repeat: no-repeat
    background-position: 50% bottom
  .content
    flex: 1 0 0
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: flex-start
    overflow: hidden
  .ticker
    margin-top: 46px
    &Text
      +montserrat(24px, 500, #fff)
    &Divider
      +montserrat(24px, 500, #fff)
      margin: 0 8px
  &Coins
    position: absolute
    bottom: 350px
    right: 0px
    padding: 100px 53px
    z-index: -1
    display: grid
    grid-template-columns: repeat(3, max-content)
    @media ( max-width: 1600px )
      padding-right: 0
      // margin-right: -100px
      // transform: translateX(100px)
      bottom: 400px
    @media ( max-width: 992px )
      bottom: 10vh
      padding: 200px 0px
      margin-right: -15vw
    @media ( max-width: 768px )
      padding: 150px 0
      margin-right: -5vw
    > img
      max-width: 100%
      &:nth-child(1)
        align-self: start
        margin-right: 63px
        animation: 3s levitate  ease-in-out infinite alternate -2.5s
        @media ( max-width: 992px )
          position: absolute
          top: -50px
          filter: blur(4px)
        @media ( max-width: 768px )
          width: 123px
          top: 25px
          left: -22px
      &:nth-child(2)
        align-self: center
        animation: 4s levitate  ease-in-out infinite alternate -2s
        @media ( max-width: 768px )
          width: 227px
      &:nth-child(3)
        align-self: end
        margin-left: 97px
        animation: 3s levitate  ease-in-out infinite alternate -1s
        @media ( max-width: 992px )
          filter: blur(4px)
          margin-left: 40px
        @media ( max-width: 768px )
          width: 102px
          margin-left: 30px

@keyframes levitate
  0%, 100%
    transform: translateY(0%)
  50%
    transform: translateY(20px)
