@import '../../../../assets/styles/mixins'

.footer
  overflow: hidden
  padding-top: 210px
  @media (max-width: 577px)
    padding-top: 120px
  @media (max-width: 390px)
    padding-top: 30px
  &Top
    max-width: 1950px
    margin: 0 auto
    padding: 0 10px
    top: -300px
    position: relative
    &Title
      position: relative
      bottom: -340px
      +montserrat(120px, 800, #fff, 120%)
      text-transform: uppercase
      text-align: right
      @media (max-width: 1530px)
        bottom: -370px
      @media (max-width: 1370px)
        bottom: -380px
      @media (max-width: 1170px)
        +montserrat(100px, 800, #fff, 120%)
        bottom: -400px
      @media (max-width: 1000px)
        bottom: -420px
      @media (max-width: 830px)
        +montserrat(80px, 800, #fff, 120%)
        bottom: -440px
      @media (max-width: 768px)
        +montserrat(68px, 800, #fff, 120%)
        bottom: -460px
      @media (max-width: 640px)
        +montserrat(60px, 800, #fff, 120%)
        bottom: -470px
      @media (max-width: 577px)
        +montserrat(50px, 800, #fff, 120%)
        bottom: -490px
      @media (max-width: 480px)
        bottom: -500px
      @media (max-width: 390px)
        +montserrat(40px, 800, #fff, 120%)
        bottom: -505px
    svg
      min-width: 2100px
      width: 100vw
      position: absolute
      top: 25%
      z-index: -1
      left: 50%
      transform: translateX(-50%)
      @media (max-width: 1000px)
        top: 30%
      @media (max-width: 768px)
        top: 50%
      @media (max-width: 640px)
        top: 55%
      @media (max-width: 577px)
        top: 60%
      @media (max-width: 480%)
        top: 65%
      @media (max-width: 390px)
        top: -5%
        min-width: 1000px
  &Bottom
    position: relative
    z-index: 5
    background: #111112
    padding: 60px 0
    @media (max-width: 768px)
      padding: 30px 0
    &Top
      display: flex
      align-items: flex-start
      justify-content: space-between
      gap: 10px
      margin-bottom: 60px
      padding: 0 10px
      @media (max-width: 1201px)
        gap: 40px
        flex-direction: column
      @media (max-width: 577px)
        margin-bottom: 40px
      .contactLink
        display: block
        margin-bottom: 24px
        +montserrat(20px, 600, rgba(255,255,255,0.4), 150%)
        transition: all 0.3s ease 0s
        &:hover
          color: rgba(255,255,255,1)
        @media (max-width: 577px)
          +montserrat(18px, 600, rgba(255,255,255,0.4), 150%)
  &Social
    &Items
      display: flex
      align-items: center
      gap: 16px
      margin-top: 24px
      .footerLink
        border-radius: 16px
        background: rgba(255, 255, 255, 0.08)
        display: flex
        align-items: center
        justify-content: center
        padding: 12px
        transition: all 0.3s ease 0s
        &:hover
          background: rgba(255, 255, 255, 0.12)
    &Item
      +montserrat(20px, 500, #fff, 140%)
      .link
        +montserrat(20px, 500, #fff, 140%)
        transition: all 0.3s ease 0s
        &:hover
          text-decoration: underline
  &CopyRight
    +montserrat(18px, 500, #fff, 140%)
    padding: 0 10px

.footerInner
  @media (max-width: 577px)
    padding: 0 !important

.footerTopDecor
  position: relative
  top: -100px
  @media (max-width: 577px)
    padding: 0 !important
  svg
    position: relative
    min-width: 100%
    max-width: 100%
    top: 420px
    width: 100%
    object-fit: contain
    @media (max-width: 1530px)
      top: 440px
    @media (max-width: 1370px)
      top: 441px
    @media (max-width: 1170px)
      top: 450px
    @media (max-width: 1000px)
      top: 460px
    @media (max-width: 830px)
      top: 470px
    @media (max-width: 768px)
      top: 480px
    @media (max-width: 640px)
      top: 490px
    @media (max-width: 577px)
      top: 495px
    @media (max-width: 480px)
      top: 500px
    @media (max-width: 390px)
      top: 503px

.navigation
  display: flex
  align-items: center
  gap: 6px
  flex-wrap: wrap
  @media (max-width: 577px)
    flex-direction: column
    align-items: flex-start
  .item
    cursor: pointer
    position: relative
    &Wrapper
      display: flex
      align-items: center
      gap: 5px
    &Link
      text-transform: uppercase
      padding: 6px 0
      transition: all ease 200ms
      +montserrat(20px, 600, rgba(255,255,255,0.4))
      margin-right: 12px
      &:hover
        color: rgba(255,255,255,1)
      &Active
        color: rgba(255,255,255,1)
      @media (max-width: 577px)
        +montserrat(18px, 600, rgba(255,255,255,0.4))
    &DD
      position: absolute
      left: -50px
      background: var(--Background-tertiary)
      padding: 8px 0
      border-radius: 16px
      width: max-content
      @media (max-width: 1400px)
        left: -2px !important
      &Child
        padding: 6px 10px
