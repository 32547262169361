@import '../../../../assets/styles/mixins'

.tokenomicsBlock
  padding-top: 320px
  position: relative
  @media ( max-width: 1440px )
    padding-top: 140px
  @media (max-width: 769px)
    padding-top: 68px
  @media (max-width: 577px)
    padding-top: 30px
  .title
    margin-bottom: 40px
    max-width: 1000px
    @media ( max-width: 1440px )
      max-width: 900px
  .text
    +mediumTextFont
    max-width: 584px
  .metaInfo
    max-width: 1000px
    margin-top: 80px
    margin-bottom: 136px
    display: flex
    flex-wrap: wrap
    gap: 64px 100px
    @media ( max-width: 1440px )
      max-width: 500px
    @media ( max-width: 768px )
      margin-bottom: 88px
      margin-top: 32px
      gap: 40px
    &Item
    &Title
      text-transform: uppercase
      +montserrat(24px, 500, rgba(255, 255, 255, 0.80), 100%)
      margin-bottom: 1em
    &Value
      +montserrat(64px, 700, #fff, 100%)
      @media ( max-width: 768px )
        +montserrat(32px, 700, rgba(255, 255, 255, 0.80), 100%)
  .decor
    position: absolute
    right: 0%
    top: -130px
    overflow: hidden
    @media ( max-width: 900px )
      overflow: visible
      width: 100%
      height: 100%
      svg
        display: none
    .spline
      position: absolute
      top: 30%
      right: 0
      width: 100%
      height: 100%
      max-width: 100%
      display: flex
      align-items: center
      justify-content: flex-end
      border-radius: 40px
      max-width: 500px
      max-height: 600px
      animation: levitate 3s ease-in-out infinite
      @media ( max-width: 1400px )
        transform: scale(0.8)
      @media (max-width: 1450px)
        top: 35%
        max-width: 400px
        max-height: 500px
    @media ( max-width: 900px )
      display: none
.decorI
  position: absolute
  opacity: 0.7
  height: 700px
  width: 700px
  border-radius: 50%
  top: 25%
  right: 0
  background: #830BE1
  filter: blur(150px)
  -webkit-filter: blur(150px)
  animation: dissipate 4s infinite alternate
  @media (max-width: 900px)
    left: -30%
    top: 20%
  @media (max-width: 769px)
    height: 500px
    width: 500px
  @media (max-width: 420px)
    height: 400px
    width: 400px
  @keyframes dissipate
    0%
      opacity: 0.2
    50%
      opacity: 0.1
    70%
      opacity: 0.15
    100%
      opacity: 0.2

@keyframes levitate
  0%
    transform: translateY(0)
  50%
    transform: translateY(-15px)
  100%
    transform: translateY(0)
