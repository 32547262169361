.suspenseLoader
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
  width: 100vw
  background-repeat: no-repeat
  background-position: center
  position: relative
  overflow: hidden
.decor
  width: 970px
  height: 970px
  border-radius: 50%
  background-color: #830BE1
  filter: blur(245px)
  opacity: 0.5
  position: absolute
  bottom: -65vh