.community
  margin-bottom: 290px
  @media (max-width: 1401px)
    margin-bottom: 280px
  @media (max-width: 1201px)
    margin-bottom: 250px
  @media (max-width: 993px)
    margin-bottom: 170px
  @media (max-width: 769px)
    margin-bottom: 110px
  @media (max-width: 576px)
    margin-bottom: 90px
  .title
    margin-bottom: 80px
    text-align: center
    @media (max-width: 769px)
      margin-bottom: 50px
    @media (max-width: 576px)
      margin-bottom: 32px
