@import './../../assets/styles/mixins'

.inner
  position: relative
  margin-top: 110px
  &Btn
    position: absolute
    left: 16px
    outline: none !important
    border: none
    border-radius: 24px
    padding: 19px 32px !important
    display: flex
    align-items: center
    justify-content: center
    gap: 16px
    user-select: none
    +montserrat(20px, 600, var(--Placeholder-primary), 150%)
    cursor: pointer
    max-width: 100%
    padding: 0 12px
    background: var(--Button-fill-primary)
    &:hover
      opacity: 0.8
    @media ( max-width: 768px )
      +montserrat(18px, 600, var(--Placeholder-primary), 150%)
    @media (max-width: 1400px)
      position: static
      margin-bottom: 24px
    @media (max-width: 576px)
      +montserrat(16px, 600, #fff, 150%)
      padding: 16px 32px !important
.container
  margin: 0 auto 180px auto
  max-width: 850px
  padding: 0 16px
  position: relative
  z-index: 3
  @media (max-width: 576px)
    margin: 0 auto 125px auto

.item
  &Top
    +montserrat(60px, 700, #fff, 150%)
    text-transform: uppercase
    margin-bottom: 24px
    @media (max-width: 769px)
      +montserrat(55px, 700, #fff, 110%)
    @media (max-width: 576px)
      +montserrat(32px, 700, #fff, 110%)
  &Content
    margin-bottom: 48px
    @media (max-width: 576px)
      margin-bottom: 32px
    img
      border-radius: 40px
      width: 100%
      height: 100%
      max-height: 700px
      object-fit: cover
      @media (max-width: 576px)
        height: 240px
    p
      +mediumTextFont
      margin-bottom: 32px
      a
        color: #AE47FF !important
        &:hover
          opacity: 0.8
          text-decoration: underline
      @media (max-width: 576px)
        +montserrat(16px, 500 rgba(255, 255, 255, 0.80), 150%)
    video
      width: 100%
      max-height: 500px
      border-radius: 40px
      object-fit: cover
      @media (max-width: 576px)
        height: 240px
