@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap')

=montserrat($size: 14px, $weight: 400, $color: white, $lineHeight: 150%)
  font-family: Montserrat, sans-serif
  font-weight: $weight
  font-size: $size
  color: $color
  line-height: $lineHeight

=textGradient($gradient)
  background: $gradient 
  background-clip: text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

=mediumTextFont
  +montserrat(20px, 500, rgba(255, 255, 255, 0.80), 150%)