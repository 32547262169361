@import '../../assets/styles/mixins'

.button
  outline: none !important
  border: none
  border-radius: 24px
  padding: 16px 32px !important
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  user-select: none
  +montserrat(20px, 600, var(--Placeholder-primary), 150%)
  cursor: pointer
  max-width: 100%
  padding: 0 12px
  @media ( max-width: 768px )
    +montserrat(18px, 600, var(--Placeholder-primary), 150%)
  &Wrapper
    -webkit-user-drag: none
    -moz-user-drag: none
    user-drag: none
.primaryVariant
  background: var(--Button-fill-primary)
  &:hover
    opacity: 0.8
.gradientVariant
  background: var(--Button-fill-gradient)
  &:hover
    opacity: 0.8
.lightVariant
  background: var(--Background-quaternary)