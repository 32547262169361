.pagination
  margin: 64px auto 0 auto
  margin-top: 64px
  position: relative
  z-index: 2
  padding: 4px
  border-radius: 24px
  background: var(--Background-tertiary)
  width: max-content
  @media (max-width: 576px)
    margin: 25px auto 0 auto
    margin-top: 25px
  &Button
    background: var(--Background-quaternary)
    border-radius: 20px
    width: 64px
    height: 64px
    padding: 0 !important
    transition: all 0.3s ease 0s
    &:disabled
      opacity: 0.2
    &:hover
      opacity: 0.7
  &Inner
    display: flex
    align-items: center
    justify-content: space-between
    gap: 16px
  &Counter
    display: flex
    align-items: center
    gap: 4px
    div
      position: static !important
      display: flex
      align-items: center
      gap: 4px
      span
        display: block
        width: 4px
        height: 40px
        background: var(--Placeholder-primary)
        border-radius: 25px
        margin: 0 !important
        cursor: pointer
        transition: all 0.3s ease 0s
        &:hover
          opacity: 0.8
