@import 'mixins'

:root
  --Main-gradient: linear-gradient(205deg, #AE47FF 1.57%, #FF47D7 79.75%)
  --Blue-gradient: linear-gradient(91deg, #0098EA 1.03%, #84D4FF 107.57%)
  --Placeholder-primary: #FFF
  --Button-fill-primary: #1E1E20
  --Button-fill-gradient: radial-gradient(185.75% 153.39% at 14.61% 4.03%, rgba(35, 22, 64, 0.80) 0%, rgba(191, 84, 241, 0.80) 36.83%, rgba(68, 37, 135, 0.80) 67.38%, rgba(19, 11, 37, 0.80) 100%)
  --Background-tertiary: #212325
  --Background-quaternary: #2E3238
  --Background-main: #111112
  --Border-primary: #2E353D
  --Accent-success: #10B981
  --Border-Secondary: #424B56

*
  box-sizing: border-box

.textMainGradient
  +textGradient(var(--Main-gradient))
.textBlueGradient
  +textGradient(var(--Blue-gradient))

.sectionTitle
  +montserrat(88px, 700, #fff, 110%)
  text-transform: uppercase
  @media (max-width: 769px)
    +montserrat(66px, 700, #fff, 110%)
  @media (max-width: 576px)
    +montserrat(32px, 700, #fff, 110%)

body
  background: var(--Background-main)
a
  text-decoration: none

.swiper-pagination-bullet-active
  height: 46px !important

.container
  margin: 0 auto
  max-width: 1712px
  padding: 0 16px
  width: 100%

div#root
  overflow: hidden

::-webkit-scrollbar
  width: 13px
  height: 15px

::-webkit-scrollbar-track
  background: #111112

::-webkit-scrollbar-thumb
  background: rgba(#830BE1, 0.8)
  border-radius: 31px

::-webkit-scrollbar-thumb:hover
  background: rgba(#830BE1, 0.8)

::-webkit-scrollbar-track:horizontal
  background: #111112

::-webkit-scrollbar-thumb:horizontal
  background: rgba(#830BE1, 0.8)

::-webkit-scrollbar-thumb:horizontal:hover
  background: rgba(#830BE1, 0.6)
