@import '../../../../assets/styles/mixins'

.faq
  margin-bottom: 200px
  @media (max-width: 1201px)
    margin-bottom: 150px
  @media (max-width: 577px)
    margin-bottom: 100px
  &Inner
    display: flex
    align-items: flex-start
    justify-content: space-between
    gap: 50px
    position: relative !important
    @media (max-width: 1201px)
      flex-direction: column
    @media (max-width: 769px)
      gap: 30px
    @media (max-width: 576px)
      gap: 20px
  &Info
    max-width: 455px
    width: 100%
    @media (max-width: 1201px)
      display: flex
      align-items: center
      justify-content: space-between
      gap: 10px
      max-width: 100%
    @media (max-width: 769px)
      flex-direction: column
      align-items: flex-start
      max-width: 100%
    &Title
      +montserrat(64px, 700, #fff, 112.5%)
      margin-bottom: 16px
      @media (max-width: 769px)
        +montserrat(42px, 700, #fff, 112.5%)
    &Description
      +montserrat(20px, 400, rgba(255, 255, 255, 0.30), 130%)
      margin-bottom: 32px
      @media (max-width: 576px)
        +montserrat(16px, 400, rgba(255, 255, 255, 0.30), 130%)
    &Block
      padding: 20px
      border-radius: 32px
      background: rgba(33, 35, 37, 0.60)
      @media (max-width: 1201px)
        min-width: 432px
      @media (max-width: 769px)
        min-width: 100%
      &T
        +montserrat(24px, 700, #fff, 150%)
        margin-bottom: 24px
        @media (max-width: 576px)
          +montserrat(24px, 700, #fff, 150%)
    &Button
      +montserrat(20px, 600, #fff, 150%)
      width: 100%
      display: block
      text-align: center
      border: 1px solid rgba(255, 255, 255, 0.50)
      background: #1E1E20
      padding: 16px
      border-radius: 24px
      transition: all 0.3s ease 0s
      &:hover
        opacity: 0.8
