@import '../../../../assets/styles/mixins'

.whitepaperBlock
  padding-top: 120px
  padding-bottom: 300px
  overflow: hidden
  position: relative
  @media ( max-width: 1400px )
    padding-bottom: 140px
  @media ( max-width: 830px )
    padding-bottom: 100px
  .splineBg
    position: absolute
    top: 0
    right: -10%
    z-index: -1
    img
      border-radius: 30px
      max-width: 100%
      max-height: 100%
    @media (max-width: 577px)
      top: -6%
  .textBlock
    margin-bottom: 120px
    @media ( max-width: 1400px )
      margin: 0 50px
    @media ( max-width: 830px )
      padding: 0
      margin: 0
    .title
      margin-bottom: 0.6em
    .textSection
      @media ( max-width: 1400px )
        max-width: 74vw
      @media ( max-width: 830px )
        max-width: 100%
      .text
        +mediumTextFont
        margin-bottom: 2em
        &:last-of-type
          margin-bottom: 2.65em
        &:nth-child(1)
          max-width: 972px
          @media ( max-width: 830px )
            max-width: 100%
        &:nth-child(2)
          max-width: 850px
          @media ( max-width: 830px )
            max-width: 100%
        @media ( max-width: 830px )
          font-size: 16px
    .button
      width: 367px
      padding: 24px 12px
      @media ( max-width: 830px )
        padding: 18px 12px
        width: 100%

  .dfcBlock
    margin-left: auto
    margin-right: 63px
    width: max-content
    position: relative
    @media ( max-width: 1400px )
      width: 100%
      padding: 0 55px
      margin-top: 128px
    @media ( max-width: 830px )
      padding: 0
      margin: 0
      margin-top: 320px
    .blob
      position: absolute
      left: 0
      transform: translateX(-100%)
      top: 58px
      width: 723px
      height: 700px
      // @media ( max-width: 1200px )
      //   width: 46vw
      @media ( max-width: 1400px )
        top: 120px
        transform: translateX(0%) scale(0.65)
        left: -46px
      @media ( max-width: 830px )
        top: 0px
        transform: translateY(-70%) translateX(-30%) scale(0.3)
      &Bg
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        background: #830BE1
        filter: blur(200px)
        -webkit-filter: blur(200px)
        border-radius: 747px
        z-index: -1
        opacity: 0.3
    .title
      margin-bottom: 0.77em
      @media ( max-width: 830px )
        margin-bottom: 42px
    .cascade
      max-width: 43vw
      @media ( max-width: 1400px )
        margin-left: auto
      @media ( max-width: 830px )
        margin-left: 0
        max-width: 100%
      &Item
        margin-bottom: 56px
        padding-left: 60px
        position: relative
        @media (max-width: 577px)
          padding-left: 44px
        &::before
          content: ""
          position: absolute
          width: 100vw
          height: 100%
          border-radius: 0px 0px 0px 33px
          border-bottom: 2px dashed #FFFFFF33
          border-left: 2px dashed #FFFFFF33
          left: 12px
          bottom: -24px
        &Dot
          position: absolute
          top: 12px
          left: 1px
          width: 24px
          height: 24px
          background: #FFFFFF33
          border-radius: 50%
          display: flex
          align-items: center
          justify-content: center
          > div
            width: 10px
            height: 10px
            border-radius: 50%
            background-color: #fff
        &Title
          +montserrat(32px, 600, #fff, 150%)
          margin-bottom: 0.625em
          @media ( max-width: 830px )
            +montserrat(24px, 600, #fff, 150%)
            margin-bottom: 20px
        &Text
          +mediumTextFont
          max-width: 883px
          @media ( max-width: 830px )
            font-size: 16px
            max-width: 100%
        &:nth-child(1)
          margin-left: 50px
          @media (max-width: 1690px)
            margin-left: 10px
          @media ( max-width: 830px )
            margin-left: 0
        &:nth-child(2)
          margin-left: -25px
          @media (max-width: 1690px)
            margin-left: -50px
          @media ( max-width: 830px )
            margin-left: 0
        &:nth-child(3)
          margin-left: -150px
          @media (max-width: 1690px)
            margin-left: -130px
          @media ( max-width: 830px )
            margin-left: 0

.sphere
  position: absolute
  top: 0
  left: 0
  max-width: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  border-radius: 40px
  max-width: 600px
  max-height: 700px
  animation: levitate 3s ease-in-out infinite
  @media ( max-width: 1300px )
    transform: scale(0.8)
  @media (max-width: 1580px)
    max-width: 550px
    max-height: 550px
    left: 15%
  @media (max-width: 1470px)
    left: 25%
  @media (max-width: 1450px)
    top: 25%
    max-width: 500px
    max-height: 600px
  @media (max-width: 1400px)
    left: 0
@keyframes levitate
  0%
    transform: translateY(0)
  50%
    transform: translateY(-15px)
  100%
    transform: translateY(0)