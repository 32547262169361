@import '../../assets/styles/mixins'

.tokenomicsCards
  position: relative
  .itemWrapper
    position: absolute
    transition: all ease 400ms
    opacity: 1
    z-index: 1
    &:nth-of-type(1)
      left: 0
    &:nth-of-type(2)
      right: 0
  .card
    background: var(--Background-tertiary)
    border-radius: 48px
    padding: 72px 72px 24px 72px
    cursor: pointer
    max-width: 80vw
    @media (max-width: 769px)
      padding: 32px 24px 24px 24px
    @media ( max-width: 1400px )
      max-width: 100vw
      margin-bottom: 48px
      &:last-child
        margin-bottom: 0
    @media (max-width: 577px)
      margin-bottom: 24px
      &:last-child
        margin-bottom: 0
    @media (max-width: 370px)
      padding: 20px
    .title
      +montserrat(64px, 700, #fff, 100%)
      text-align: center
      margin-bottom: 0.75em
      text-transform: uppercase
      @media ( max-width: 768px )
        +montserrat(24px, 700, #fff, 130%)
        margin-bottom: 1.5em
      @media (max-width: 577px)
        margin-bottom: 24px
      @media (max-width: 370px)
        margin-bottom: 5px
    .diagram
      display: flex
      align-items: center
      justify-content: center
      > svg
        max-width: 100%
