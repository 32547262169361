@import '../../assets/styles/mixins'

.card
  padding: 24px 24px 40px 24px
  overflow: hidden
  border-radius: 48px
  background: rgba(33, 35, 37, 0.80)
  display: flex
  align-items: center
  flex-direction: column
  width: 800px
  @media (max-width: 1250px)
    width: 700px
  @media (max-width: 830px)
    width: 100% !important
    max-width: 100% !important
  &Img
    img
      border-radius: 50%
      width: 100px
      height: 100px
      object-fit: cover
      margin-bottom: 24px
  &Title
    +montserrat(24px, 400, var(--Placeholder-primary), 130%)
    margin-bottom: 24px
    text-align: center
    @media (max-width: 576px)
      +montserrat(18px, 400, var(--Placeholder-primary), 130%)
  &Divider
    width: 100%
    height: 1px
    background: var(--Border-primary)
    margin-bottom: 24px
  &Description
    +montserrat(24px, 400, var(--Placeholder-primary), 130%)
    text-align: center
    @media (max-width: 576px)
      +montserrat(16px, 400, var(--Placeholder-primary), 130%)
