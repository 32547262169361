.news
  position: relative
  margin-bottom: 320px
  @media (max-width: 1401px)
    margin-bottom: 280px
  @media (max-width: 1201px)
    margin-bottom: 250px
  @media (max-width: 993px)
    margin-bottom: 170px
  @media (max-width: 769px)
    margin-bottom: 110px
  @media (max-width: 576px)
    margin-bottom: 90px
  .decor
    position: absolute
    left: 0
    top: -50%
    display: flex
    align-items: center
    justify-content: center
    &I
      position: absolute
      opacity: 0.2
      height: 700px
      width: 700px
      border-radius: 50%
      left: -50%
      background: #830BE1
      filter: blur(70px)
      -webkit-filter: blur(70px)
      animation: dissipate 4s infinite alternate
      @keyframes dissipate
        0%
          opacity: 0.2
        50%
          opacity: 0.1
        70%
          opacity: 0.15
        100%
          opacity: 0.2
      @media (max-width: 769px)
        height: 500px
        width: 500px
      @media (max-width: 420px)
        height: 400px
        width: 400px
    @media (max-width: 540px)
      top: -70%
    @media (max-width: 420px)
      top: -100%
    @media (max-width: 400px)
      top: -70%
    svg
      max-width: 100%
      max-height: 100%
  .title
    margin-bottom: 88px
    @media (max-width: 769px)
      margin-bottom: 50px
    @media (max-width: 576px)
      margin-bottom: 32px
